import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map } from 'lodash';
import { ComponentExporterService } from '../components/stand-alone/exporter/component-exporter-new.service';

@Injectable({
	providedIn: 'root',
})
export class ComponentResolverService {
	constructor(private exporterService: ComponentExporterService) {}

	/**
	 * Resolved an component based of routes paths
	 * @param component
	 */
	public resolveComponent(component: string): Observable<any> {
		return from(
			(async () => {
				let componentsMap = await this.exporterService.resolveComponent(component).then((component) => map(component));
				if (componentsMap.length > 1) {
					console.log('Multiple components found with sane name, resolving to the first matching one');
				}
				const matchingComponent = componentsMap.find((standardComponent: any) => {
					if (standardComponent?.prototype?.resolveComponentName) {
						let componentName = standardComponent?.prototype?.resolveComponentName();
						if (component == componentName) return componentName;
					}
				});
				return matchingComponent;
			})()
		);
	}
}
